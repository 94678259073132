import React from 'react'
import AdvocateBigSection from './AdvocateBigSection'

function Team() {
    return (
        <div className="team__page">
            <section className="page__section _about_top">
                <div className="wrapper">
                    <h1>Team</h1>
                </div>
            </section>
            <AdvocateBigSection />
        </div>
    )
}

export default Team
