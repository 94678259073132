import React, { useEffect, useState  } from 'react';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { db } from './firebase';

function TestimonialsList(props) {

  const [mainSlider, setMainSlider] = useState(undefined);
  const [navSlider, setNavSlider] = useState(undefined);

  const mainSettings = {
    accessibility: true,
    autoplay: true,
    autoplaySpeed: 10000, //10s
    arrows: false,
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'no-dots',
    ref: (slider) => (setMainSlider(slider)),
    asNavFor: navSlider
  }
  const navSettings = {
    accessibility: true,
    autoplay: true,
    autoplaySpeed: 10000, //10s
    arrows: false,
    dots: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [{
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
      }
    }],
    //className 		: 'slick-left-align',
    ref: (slider) => (setNavSlider(slider)),
    asNavFor: mainSlider,
  }
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
      db.collection('testimonials').onSnapshot(snapshot => {
          setTestimonials(snapshot.docs.map(doc => ({'id': doc.id, 'data': doc.data()})));
      });
  }, []);
  

  return (
    <div id='listingsSection'>
        <Slider {...mainSettings}>

        {
        testimonials.map(({id, data}) => (
          <div key={id} className="testimonial__text">
              <p>{data.comments}</p>
          </div>
        ))
        }
          
        </Slider>
        <Slider {...navSettings}>
        {
        testimonials.map(({id, data}) => (
          <div key={id} className="testimonial__profile">
              <div className="__detail">
                  <h4>{data.name}</h4>
                  <p>{data.role}</p>
              </div>
          </div>
        ))
        }
        </Slider>
    </div>
  )

}

export default TestimonialsList
