import React, { useEffect, useState } from 'react'
import AdvocateBigCard from './AdvocateBigCard';
import { db } from './firebase';

function AdvocateBigSection() {
    const [advocates, setAdvocates] = useState([]);
    useEffect(() => {
        db.collection('advocates').onSnapshot(snapshot => {
          setAdvocates(snapshot.docs.map(doc => ({'id': doc.id, 'data': doc.data()})));
        });
      }, []);
    return (
        <section className="page__section _big_team">
        <div className="wrapper">
          <div className="section__head">
            <h2>Our Team</h2>
          </div>
          <div className="section__body">
            {
              advocates.map(({id, data}) => (
                <AdvocateBigCard key={id} item-data={data} />
              ))
            }
          </div>
          </div>
        </section>
    )
}

export default AdvocateBigSection
