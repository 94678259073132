import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBPTxybph1p3qCACQs4z35IpjschU1aLGM",
  authDomain: "jahangir-associates-18c9c.firebaseapp.com",
  projectId: "jahangir-associates-18c9c",
  storageBucket: "jahangir-associates-18c9c.appspot.com",
  messagingSenderId: "164211921201",
  appId: "1:164211921201:web:b98808de0a0a2d09612010",
  measurementId: "G-M2ZPW5791D"
});

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const storage = firebaseApp.storage();

export {db, auth, storage};