import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import { Link } from 'react-router-dom';

function AdvocateSmallCard(props) {
    const {name, about, img} = props['item-data'];
    return (
        <div className="attorney_card">
            <div className="__img">
                <img src={img} alt=""/>
            </div>
            <div className="__details">
                <h3>{name}</h3>
                <LinesEllipsis
                    text={about}
                    maxLine='3'
                    ellipsis='...'
                    trimRight
                    basedOn='letters'
                    />
                <Link to="/team" className="btn">View Profile</Link>
            </div>
        </div>
    )
}

export default AdvocateSmallCard
