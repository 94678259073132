import React from 'react'
import PracticeAreaSection from './PracticeAreaSection'

function About() {
    return (
        <div className="about__us">
            <section className="page__section _about_top">
                <div className="wrapper">
                    <h1>About us</h1>
                </div>
            </section>
            <section className="page__section _text">
                <div className="wrapper">
                    <div className="section__body">
                        <p>Jahangir Associates offers a wide range of legal services in the area of Corporate
& Commercial Laws, Dispute Resolution, Tax (Direct & Indirect) and
Intellectual Property (IPR) and specializes in representing foreign and domestic

corporations with diverse business interests in India.</p>
<p>Our Firm has lawyers who understand numbers, finance
              and taxation. We know how to legally save your money
              and value your time. Time is non-negotiable and we
              undertake to deliver in a time bound efficient manner.
              We help you keep your business clean and in compliance
              with the laws. We safeguard your interest legally at all
              steps.</p>

              <p>Our forte lies in investment advisory and strategy
              building for your organizations thereby helping you to
              grow. We also help SME's list their companies on the
              stock exchange.</p>

              <p>We are a one stop solution to your legal, financial and
              compliance needs.</p>
                    </div>
                </div>
            </section>
            <PracticeAreaSection />
        </div>
    )
}

export default About
