import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import Misc from './variables.js';
import { Link } from 'react-router-dom';
import Logo from './assets/img/logo/gold.png'

function MenuLinks() {
    const goBack = () => {
        window.history.back();
    }
    return (
        <div className="header__links">
            <Link className="header__logo" to="/"><img src={Logo} alt="" /></Link>
            <span className="__close" onClick={goBack}><CloseIcon style={{ color: Misc.Color, fontSize: 40 }} /></span>
            <Link to="/about-us">About Us</Link>
            <Link to="/terms">Terms</Link>
            <Link to="/contact-us">Contact Us</Link>
        </div>
    )
}

export default MenuLinks
