import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import About from "./About";
import Footer from "./Footer";
import Header from "./Header";
import Home from "./Home";
import Terms from "./Terms";
import Menu from "./Menu";
import ContactUs from "./ContactUs";
import Team from "./Team";
import Privacy from "./PrivacyPolicy";
import DOC from './assets/doc/jahangir-associates-services.pdf';

function App() {
  return (
    <Router>
        <Switch>
          <Route path="/menu">
            <Menu />
          </Route>
          <Route path="/team">
            <Header name="_bg_transparent"></Header>
              <Team />
            <Footer/>
          </Route>
          <Route path="/about-us">
            <Header name="_bg_transparent"></Header>
              <About />
            <Footer/>
          </Route>
          <Route path="/terms">
            <Header name="_bg_transparent"></Header>
            <Terms />
            <Footer/>
          </Route>
          <Route path="/privacy-policy">
            <Header name="_bg_transparent"></Header>
            <Privacy />
            <Footer/>
          </Route>
          <Route path="/contact-us">
            <Header name="_bg_transparent"></Header>
            <ContactUs />
            <Footer/>
          </Route>
          <Route path="/business-pitch">
            <iframe title="Business Pitch" src={DOC} width="100%" height="100%" />
          </Route>
          <Route path="/">
            <Header name="_bg_transparent"></Header>
            <Home />
            <Footer/>
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
