import React from 'react'
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import MenuLinks from './MenuLinks.js';
import Logo from './assets/img/logo/white.png';
import Disclaimer from './Disclaimer';

export default function Header(props) {
    return (
        <div>
            <Disclaimer />
            <header className={props ? props.name : ''}>
                <Link className="header__logo" to="/"><img src={Logo} alt="" /></Link>
                <Link className="__hamb_menu" to="/menu"><MenuIcon style={{ color: "#fff", fontSize: 40 }} /></Link>
                <MenuLinks />
            </header>
        </div>
    )
}
