import React from 'react'
import { Link } from 'react-router-dom';
import Misc from './variables.js';
import InstagramIcon from '@material-ui/icons/Instagram';
import Logo from './assets/img/logo/white.png'
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import RoomIcon from '@material-ui/icons/Room';

function Footer() {
    return (
        <footer>
            <div className="footer_row">
                <div className="footer_col _about">
                    <Link className="__logo" to="/"><img src={Logo} alt="" /></Link>
                    <div className="__details">
                        <p className="__about">Jahangir Associates offers a wide range of legal services in the area of Corporate & Commercial Laws, Dispute Resolution, Tax (Direct & Indirect) and Intellectual Property (IPR) and specializes in representing foreign and domestic  corporations with diverse business interests in India.</p>
                        <a className="__tel" href={"tel:"+Misc.HelpLine}>
                            <HeadsetMicIcon /> {Misc.HelpLine}
                        </a>
                        <address><RoomIcon /> {Misc.Address}</address>
                    </div>
                </div>
                <div className="footer_col">
                    <h2>Navigation</h2>
                    <div className="__links">
                        <Link to="/">Home</Link>
                        <Link to="/about-us">About us</Link>
                        <Link to="/team">Team</Link>
                        <Link to="/business-pitch" target="_blank">Business Pitch</Link>
                    </div>
                </div>
                <div className="footer_col">
                    <h2>Licence</h2>
                    <div className="__links">
                        <Link to="/privacy-policy">Privacy</Link>
                        <Link to="/terms">Terms</Link>
                        <Link to="/contact-us">Contact us</Link>
                    </div>
                </div>
                <div className="footer_col">
                    <h2>Social Media</h2>
                    <div className="__links">
                        <InstagramIcon style={{fontSize : 30}} />
                    </div>
                </div>
            </div>
            <div className="footer_row _copy_right">
                <div className="footer_col">Copyright  All Rights Reserved</div>
            </div>
        </footer>
    )
}

export default Footer
