import MenuLinks from './MenuLinks'
import React from 'react'

function Menu() {
    return (
        <div className="__menu_links">
            <MenuLinks />
        </div>
    )
}

export default Menu
