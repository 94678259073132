import './style/home.scss';
import MetaTags from 'react-meta-tags';

// import { db } from './firebase';
// import { useEffect, useState } from 'react';
// import Representatives from './Representatives';
import PracticeAreaSection from './PracticeAreaSection';
import AdvocateSmallSection from './AdvocateSmallSection';
import { Link } from 'react-router-dom';
import ContactSection from './ContactSection';
import TestimonialsSection from './TestimonialsSection';

import IMAGE1 from './assets/img/expertise/1.jpg'
import IMAGE2 from './assets/img/expertise/2.jpg'
import IMAGE3 from './assets/img/expertise/3.jpg'
import FAVICON from './assets/img/favicon.png'

function Home(props) {

  // const [representatives, setRepresentatives] = useState([]);

  // useEffect(() => {
  //   db.collection('representatives').onSnapshot(snapshot => {
  //     setRepresentatives(snapshot.docs.map(doc => ({'id': doc.id, 'data': doc.data()})));
  //   });
  // }, []);

  return (
    <div className="home__page">
      <MetaTags>
        <title>Jahangir Associates</title>
        <meta name="description" content="Jahangir Associates offers a wide range of legal services in the area of Corporate & Commercial Laws, Dispute Resolution, Tax (Direct & Indirect) and Intellectual Property (IPR) and specializes in representing foreign and domestic  corporations with diverse business interests in India." />
        <meta property="og:title" content="Jahangir Associates" />
        <meta property="og:image" content={FAVICON} />
      </MetaTags>
      <section className="page__section _top">
        <div className="wrapper">
          <div className="section__body">
            <h1>Protect What You Value Most</h1>
            <h2><span className="__line"></span>Corporate Law <span className="__dot">.</span> Finance <span className="__dot">.</span> M&A  <span className="__dot">.</span> PE Fund Raise<span className="__line"></span></h2>
            <div className="__action"><Link to="/contact-us" className="btn _prime _big">Request a free consultation</Link></div>
          </div>
        </div>
      </section>

      {/* <section className="page__section _services">
        <div className="wrapper">
          <div className="section__body">
            {
              representatives.map(({id, data}) => (
                <Representatives key={id} item-data={data} />
              ))
            }
          </div>
        </div>
      </section> */}

      <PracticeAreaSection />
      
      <section className="page__section _we_help">
        <div className="wrapper">
          <div className="section__head">
            <h2>Our Expertise</h2>
            <p>Our Firm has lawyers who understand numbers, finance
              and taxation. We know how to legally save your money
              and value your time. Time is non-negotiable and we
              undertake to deliver in a time bound efficient manner.
              We help you keep your business clean and in compliance
              with the laws. We safeguard your interest legally at all
              steps.</p>

              <p>Our forte lies in investment advisory and strategy
              building for your organizations thereby helping you to
              grow. We also help SME's list their companies on the
              stock exchange.</p>

              <p>We are a one stop solution to your legal, financial and
              compliance needs.</p>
              <Link to="/contact-us" className="btn _prime">Contact Us</Link>
          </div>
          <div className="section__body">
            <div>
              <img src={IMAGE1} alt=""/>
            </div>
            <div>
              <img src={IMAGE2} alt=""/>
              <img src={IMAGE3} alt=""/>
            </div>
          </div>
        </div>
      </section>
      <AdvocateSmallSection />
      <ContactSection />
      <TestimonialsSection />
    </div>
  );
}

export default Home
