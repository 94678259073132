import React from 'react'

function PrivacyPolicy() {
    return (
        <div className="about__us">
            <section className="page__section _about_top">
                <div className="wrapper">
                    <h1>Privacy Policy's</h1>
                </div>
            </section>
            <section className="page__section _text">
                <div className="wrapper">
                    <div className="section__body">
                    <p>By accessing and/or using the Platforms or Online Content, you agree to be bound by these Terms of Use, whether you are:
                    a “Visitor,” meaning you simply browse www.Jahangir Association academy.com without limitation, through a mobile or other wireless device, or otherwise use the Platforms without being registered, or
                    a “Student,” meaning that you have registered with Jahangir Association with a User ID and User Password (defined below), will complete the homework assignments and exams, and have enrolled in and paid for access to services, content, and/or certification provided by Jahangir Association or its Educational Partners (defined below), attend or view lectures online and accessing final projects or assessments required for certification.
                    The term “User” refers to a Visitor, Registered or Paid Attendees.
                    You are authorized to use the educational services only if you agree to abide by all applicable laws, rules and regulations and these Terms of Use.</p>

                    <p>IF YOU DO NOT AGREE TO THESE TERMS OF USE, THEN YOU MAY NOT ACCESS OR USE THE EDUCATIONAL OR THE ONLINE CONTENT . Jahangir Association ’S ACCEPTANCE IS EXPRESSLY CONDITIONED UPON YOUR ASSENT TO ALL OF THE TERMS AND CONDITIONS OF THESE TERMS OF USE, TO THE EXCLUSION OF ALL OTHER TERMS.</p>
                    <p>We reserve the right to change or modify these Terms of Use at our sole discretion at any time. Any change or modification to these Terms of Use will be effective immediately upon posting by Jahangir Association on a Platform. You are responsible for periodically reviewing the most up to date version of these Terms of Use.</p>
                    <p>Your continued use of the Platforms or Online Content constitutes acceptance of any changes or modification made by us to these Terms of Use.</p>
                    <p>Offerings; certification and Educational Partners</p>
                    <p>Jahangir Association (herein referred to as “Jahangir Association ”) is an online caching class/ education services provider, involved in the conduct of various professional education Offerings more specifically company secretary (“CS”) course that lead to the required guidance and mentoring to appear and clear the CS exam conducted by the Institute of Company Secretaries of India (“ICSI”) , upon successful completion by the enrolled candidates.</p>

                    <p>Jahangir Association is by itself NOT a University/ College/ School, and the use of the term “Jahangir Association ” is a coined term used as a brand name, for only for the purpose of convenience and is not to mean or be interpreted as anything else under any applicable Indian laws</p>

                    <p>Through the Site, Jahangir Association offers Online Content, Offerings, videos, test series, notes or other offerings in specific areas of study or on particular topics of professional exams subject wise (each, a “Offering”). Jahangir Association reserves the right to cancel, reschedule any Offering or modify its content as well as the point value or weight of any assignment, exam or other evaluation of progress that may be provided in connection with the Offerings offered and are subject to the Agreements, including without limitation the Warranty Disclaimer and Limitation of Liability sections below.</p>

                    <p>Jahangir Association may offer a credential or other acknowledgment for participants who have satisfactorily demonstrated mastery of the Offering material. The decision to award any such credential acknowledgment to a given participant will be solely at the discretion of Jahangir Association.</p>
                    <p>Jahangir Association may choose not to offer any credential or other acknowledgment for some Offerings. It can decide in its sole discretion whether to provide a record concerning a participant’s performance in a Offering. The format of any credential or other acknowledgement, and of any performance, provided by Jahangir Association relating to Offerings will be determined by Jahangir Association in its sole discretion and may vary from Offering to Offering..</p>

                    <p>Jahangir Association and the instructors of the CS Online Content reserve the right to alter, modify or rearrange the schedule of topics for the Online Content. </p>

                    <p>By attending the Online Content , </p>

                    <p>Students acknowledge that Jahangir Association and the instructors maintain the right and ability to adapt, modify or revise the Online Content as Jahangir Association and the instructors deem appropriate. The course content is only for the purpose of assisting the students to clear the Company secretary exam.</p>

                    <p>The Platforms are available to any User. However, access to the prescribed Educational Content may be restricted to Attendees or Students that have a registered User Account. </p>

                    <p>By registering, you agree that:</p>
                    <ul>
                        <li>You are registered for the course and educational content only once and will not set up multiple User Accounts; and you will abide by these Terms of Use.</li>
                        <li>Additional requirements apply to Students. As a Student, in addition to the points above, you agree that: you are registering with the intent to gain knowledge for the CS syllabus, , that are relevant and applicable to the CS course or the CS Online Content for which you have enrolled or registered.</li>
                    </ul>

                    <p>The Users will not do the following activities:</p>
                    <ul>
                        <li>Will not harass other Students, Attendees, Support Agents or Visitors;</li>
                        <li>Will not post online any Secured Testing Materials (as defined below);</li>
                        <li>Will not share solutions to homework assignments or exams;</li>
                        <li>Will not share the videos/ course content to other non Users</li>
                        <li>and Will notify the instructors immediately if he or she becomes aware of any other User cheating or breaching these Terms of Use.</li>
                        <li>And will comply with the requirements of the specific applicable code of conduct, if any, relating directly to a Offering in which such User is enrolled.</li>
                    </ul>

                    <div class="terms_list">
                        <h2>User Conduct Policy</h2>
                        <p>To the extent you are registered or enrolled on the portal, or purchase the CS video lectures or the test series or any other item from the portal, you do so at your own will and with its own policy regarding and that you will abide by the User Conduct Policy as listed below:</p>
                    </div>

                    <div class="terms_list">
                        <h2>Refund Policy</h2>
                        <p>Unless otherwise expressly agreed in writing by Jahangir Association , full payment or partial payment for subscription in a CS course /Offering/ module is required at the time of registration.</p>
                        <p>Specific payment requirements provided to you at the time of registration or on the applicable, module/Offering-specific web page. In case of subscriptions to Offerings or subscription to products or services that include a free trial the full payment will be due upon such free trial period expiring.</p>
                        <p>It is the sole responsibility of the student enrolling for a Offering to check the accuracy of, and evaluate the suitability and relevance of, the Offering elected. No refund will be made once payment for a Offering has been made. The enrolment is non-transferable.</p>
                    </div>

                    <div class="terms_list">
                        <h2>Cancellation Policy</h2>
                        <p>You may cancel your enrolment / subscription at any time. We do not refund the fee once proof of delivery has been generated.</p>
                        <p>All subscriptions have limit to view digital content / learning videos and will allow user to access learning material only three times. You may cancel your subscription by going to your account settings. You will continue to have access to all Jahangir Association  until the end of your term.</p>
                        <p>Please refer to our Terms of use for all the details on our refund policy.</p>
                    </div>

                    <div class="terms_list">
                        <h2>Services Availability and Limitations</h2>
                        <p>You agree that Jahangir Association  Academy, in its sole discretion, may terminate your use of the Site or your participation in any Offering, for any reason or no reason if Jahangir Association terminates your participation in an Offering other than for a cause, including, without limitation, your failure to comply with any term of use, you will receive a pro-rata refund of any fees you paid in connection with the Offering. It is Jahangir Association ’s policy to terminate, under inappropriate circumstances, users of the Site who are violating the terms of use of Jahangir Association as stated hereunder.</p>
                        <p>Jahangir Association will not be required to provide you any service or engage in any activity including but not limited to providing you Offerings and/or credentials or acknowledgements relating believes that the service or activity may violate any applicable law or regulation. You further acknowledge that for the purpose of any Offering your sole relationship with Jahangir Association is in Agreement. Termination does not give rise to any right to any grievance or other resolution process for student disputes at Jahangir Association , or any other school, division, Offering of or associated with Jahangir Association . You agree that Jahangir Association has the right to cancel, delay, reschedule, or alter the format of any Offering at any time. If you no longer use any Offering or use the Site, you may terminate your participation upon notice to Jahangir Association . The rights granted to you hereunder will terminate upon any termination of your right to participation in a Offering, but the other provisions of the Agreements will survive any such termination. You acknowledge and agree that Jahangir Association ’s services, including paid services and products, are not available at all times, and that certain usage limits may apply. You also understand that Jahangir Association , at its sole discretion, may limit, suspend, or terminate your use of all Jahangir Association -provided services related to Jahangir Association ’s Offerings, such as access to Jahangir Association coaches or support services, evaluation services, or certifications. You also understand that Jahangir Association may modify or discontinue all services related to its Offerings at its sole discretion.</p>
                    </div>
                    <div class="terms_list">
                        <h2>Indemnity</h2>
                        <p>You agree to indemnify and hold harmless Jahangir Association from any and all claims, liabilities, damages, losses and expenses, including reasonable attorneys’ fees and costs, relating to or arising out of (a) your use or attempted use of the Site or Offerings in violation of the Agreements; (b) your violation of any law or rights of any third party; or (c) information or content that you or others post or otherwise make available on the Site or through any Offering, including without limitation any claim of infringement or misappropriation of intellectual property or other proprietary rights.</p>
                    </div>
                    <div class="terms_list">
                        <h2>Termination of Rights</h2>
                        <p>You agree that Jahangir Association , in its sole discretion, may deactivate your account or otherwise terminate your use of the Platforms or Online Content for any reason or no reason, including, without limitation, if Jahangir Association believes that you have (a) breached these Terms of Use; (b) infringed the intellectual property rights of a third party; (c) posted, uploaded or transmitted Unauthorized Content to the Platforms; or (d) violated or acted inconsistently with the letter or spirit of these Terms of Use or any applicable code of conduct. </p>
                        <p>You agree that any deactivation or termination of your access to the Platforms or Online Content may be effected without prior notice to you and that Jahangir Association shall not be liable to you nor any third party for any termination of your account or the Online Content . You also acknowledge that Jahangir Association may retain and store your information on Jahangir Association ’s systems notwithstanding any termination of your account or the Online Content.</p>
                        <p>Any notice which may be required to be given to us under these Terms of Use may be sent to us by writing or emailing to the following addresses:</p>
                        <p>Jahangir Association  Academy, Phone: +91 97692 97903Email: info@JahangirTutorials.com</p>
                    </div>

                    <div class="terms_list">
                        <h2>THE FOLLOWING KINDS OF CONTENT (“PROHIBITED CONTENT”) ARE STRICTLY PROHIBITED ON THE SITE:</h2>
                        <ul>
                            <li>Content that defames, harasses, discriminates against, harms or threatens others;</li>
                            <li>Content that discusses illegal activities, with the intent to commit them;</li>
                            <li>Content that infringes or misappropriates patent, trademark, trade secret, right of publicity, or other intellectual property rights of third parties;</li>
                            <li>Content that you do not have the right to disclose;</li>
                            <li>Profane, pornographic, obscene, indecent or unlawful content;</li>
                            <li>Advertising or any form of commercial solicitation;</li>
                            <li>Content related to partisan political activities; and</li>
                            <li>Content that contains intentionally inaccurate information or that is posted with the intent of misleading others.</li>
                            <li>Content that contains any viruses, Trojan horses, worms, time bombs, cancel bots, or other disabling devices or other harmful components intended to or that may damage, detrimentally interfere with, surreptitiously intercept, or expropriate any system, data, or personal information; fraudulent, indecent, or libellous or that defames, abuses, harasses, discriminates against or threatens others;</li>
                            <li>that consists of any high volume, automated, or electronic means to access the Platforms (including without limitation robots, spiders or scripts); that violates the rights of other Users of the Platforms; or that violates any applicable local, state, national or international law or otherwise advocates or encourages any illegal activity.</li>
                        </ul>
                    </div>

                    <div class="terms_list">
                        <p>As a condition of accessing the Platforms and/or using the Online Content or Offerings, you agree not to</p>
                        <p>(a) reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes any portion of the Online Content or Offerings other than as expressly allowed under these Terms of Use;</p>
                        <p>(b) use Jahangir Association ’s name, trademarks, service marks, or other materials in connection with, or to transmit, any unsolicited communications or emails;</p>
                        <p>(c) use any high volume, automated, or electronic means to access the Platforms (including without limitation robots, spiders or scripts); </p>
                        <p>(d) frame the Platforms, place pop-up windows over its pages, or otherwise affect the display of its pages; </p>
                        <p>(e) falsely state, impersonate, or otherwise misrepresent your identity, including but not limited to the use of a pseudonym or misrepresenting your affiliations with a person or entity, past or present; </p>
                        <p>(f) force headers or otherwise manipulate identifiers in order to disguise the origin of any communication transmitted through the Online Content ; or</p>
                        <p>(g) interfere with or disrupt the Online Content or Offerings or servers or networks connected to the Online Content or Offerings, or disobey any requirements, procedures, policies or regulations of networks connected to the Online Content or Offerings.</p>
                    </div>
                    <div class="terms_list">
                        <h2>Jahangir Association ’s Intellectual Property Rights</h2>
                        <p>The Platforms, Online Content, Lessons , Videos , eBooks and /audio Books/ Test series/ notes/ syudy material and Offerings are owned and operated by Jahangir Association and its licensors. Jahangir Association reserves the right at any time and from time to time to modify, discontinue, temporarily or permanently, the Platforms, Online Content , Offerings or any part or portion thereof, with or without notice to you. You agree that Jahangir Association shall not be liable to you or to any third party for any modifications, suspensions or discontinuance of the Platforms, Online Content or Offerings, or any portion thereof. Nothing in these Terms of Use shall be construed to obligate Jahangir Association to maintain and support the Platforms, Online Content or Offerings or any part or portion thereof, during the term of these Terms of Use.</p>
                        <p>All content or other material available on the Platforms or through the Online Content , including but not limited to on-line lectures, speeches, video lessons, quizzes, presentation materials, home assignments, assignments, Offerings, code, and other images, text, layouts, arrangements, displays, illustrations, documents, materials, audio and video clips, HTML and files (collectively, the “Content”), are the property of Jahangir Association  or its affiliates or licensors and are protected by copyright, patent and/or other proprietary intellectual property rights under Indian and foreign law. All Jahangir Association Content is the property of Jahangir Association and/or its affiliates or licensors and is protected by copyright, patent and/or other proprietary intellectual property India and foreign law.</p>
                        <p>Jahangir Association logos, trademarks and service marks which may appear on the Platforms (“Marks”), are the property of Jahangir Association and are protected under Indian and foreign laws. All other trademarks, service marks and logos used on the Platforms, Online Content or Offerings, with or without attribution, are the trademarks, service marks or logos of their respective owners. In addition, elements of the Platforms are protected by trade dress and other central and state intellectual property laws and may not be copied, reproduced, downloaded or distributed in any way in whole or in part without the express written consent of Jahangir Association .</p>
                        <p>Except as otherwise expressly permitted in these Terms of Use, you may not copy, sell, re-sell, display, reproduce, publish, modify, create derivative works from, transfer, distribute or otherwise commercially exploit in any manner the Platforms, Online Content , Offerings or any Content. You may not reverse-engineer, decompile, disassemble or otherwise access the source code for any software that may be used to operate the Online Content . From time to time, Jahangir Association may include software, code, instructions, or other such information in the Content or materials for the Online Content ; any such information is provided on an “as-is” basis for instructional purposes only and is subject to the Disclaimer of Warranties and Limitation of Liabilities sections below and other terms herein. Any use of such information for commercial purposes is strictly prohibited. Jahangir Association and/or its affiliates and licensors reserve all rights not expressly granted herein to the Platforms, Online Content, Content, and Marks.</p>
                        <p>License to Use the Platforms, Content and Online Content and Offerings</p>
                        <p>In consideration for your agreement to these Terms of Use, Jahangir Association grants you a personal, non-exclusive, non-transferable, revocable license to access and use the Site solely in accordance with Agreements. Jahangir Association may make Content (as defined above) available to you on the Site or through the Offerings. You may obtain and use Content only for your own personal use in connection with browsing the Site or participating in Offerings. You may only copy and modify Content as necessary for your own personal, non-commercial purposes and not transfer, retransmit, distribute, publish, commercially exploit or create derivative works of Content. You may not reverse-engineer, decompile, disassemble or otherwise code any software that may be used to operate the Site or the Offerings. Notwithstanding the foregoing, certain reference documents, digital textbooks, articles and other Site may be made available to you with the permission of third parties, and use of that information is subject to certain rules and conditions, which will be posted on the Site which you agree to abide by all such rules and conditions.</p>
                    </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PrivacyPolicy
