import React, { useState } from 'react'
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import CommentIcon from '@material-ui/icons/Comment';
import { db } from './firebase';
import validator from 'validator';
import emailjs from 'emailjs-com';
// import emailjs from '@emailjs/browser';


import Misc from './variables';
function ContactSection() {

    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [submit_txt, setSubmitText] = useState('Submit');

    const contactUs = (event) => {
        event.preventDefault();
        if(!name) {
            alert('Please enter name');
            return false;
        }
        if(!mobile || isNaN(mobile)) {
            alert('Please enter valid mobile number');
            return false;
        }
        if(!validator.isEmail(email)) {
            alert('Please enter valid email address');
            return false;
        }
        setSubmitText('Loading...');
        db.collection('contact_us').add({
            name: name,
            mobile: mobile,
            email: email,
            comment: comment,
            timestamp: Misc.Time
        }).then(()=> {

          setSubmitText('Submit');
          alert('Thanks, will get in touch soon');
          emailjs.sendForm('service_dg6bd9e', 'template_4fqjo66', event.target, 'user_JU3amahv7sKhaaz0LUSIm')
         .then((result) => {
          setName('');
          setEmail('');
          setMobile('');
          setComment('');
          setSubmitText('Submit');
          alert('Thanks, will get in touch soon');
         }, (error) => {

            setName('');
            setEmail('');
            setMobile('');
            setComment('');
             console.log(error.text);
         });
        });
    }

    return (
            <section className="page__section _contact_us" id="sContact">
        <div className="wrapper">
          <div className="section__head">
            <h2>Request A Consultation</h2>
            <p>If you would like an attorney to contact you for a free consultation, please complete this form or call us at {Misc.HelpLine}</p>
          </div>
          <div className="section__body">
            <form onSubmit={contactUs}>
              <input type="hidden" name="to_name" value="Admin" />
              <input type="hidden" name="from_name" value="System Watchdog" />
            <div className="contact__form">
              <div className="form__row">
                <div className="form__input">
                  <PersonIcon style={{ color: "#ddd" }}/>
                  <input placeholder="Name" type="text" name="name" onChange={event => setName(event.target.value)} value={name} />
                </div>
                <div className="form__input">
                  <PhoneIcon style={{ color: "#ddd" }}/>
                  <input maxLength="10" placeholder="Mobile" type="tel" name="mobile" onChange={event => setMobile(event.target.value)} value={mobile} />
                </div>
              </div>
              <div className="form__row">
                <div className="form__input">
                  <EmailIcon style={{ color: "#ddd" }}/>
                  <input placeholder="Email Id" type="email" name="email" onChange={event => setEmail(event.target.value)} value={email} />
                </div>
              </div>
              <div className="form__row">
                <div className="form__input">
                  <CommentIcon style={{ color: "#ddd" }}/>
                  <textarea rows="5" placeholder="Comment" name="comment" onChange={event => setComment(event.target.value)} value={comment}></textarea>
                </div>
              </div>
              <div className="form__row _action">
              <input className="btn _prime" id="aSubmit" type="submit" value={submit_txt} />
              </div>
            </div>
            </form>
          </div>
        </div>
      </section>
    )
}

export default ContactSection
