const DOM = window.location;
const Misc = {
    AppCode     : 'WEBSITE',
    Time        : new Date(),
    CompanyName : 'Jahangir Associates',
    User        : {},
    SiteUrl     : DOM.protocol + '//' + DOM.host,
    AjaxURL     : DOM.protocol + '//' + DOM.host + '/ajax.php',
    ApiURL	 	: DOM.protocol + '//' + DOM.host + '/api/',
    Protocol    : DOM.protocol,
    Host        : DOM.host,
    ImagePath   : '/assets/img/',
    IconPath    : '/assets/icon/',
    DashBoard   : '/app/',
    Location    : {},
    Selected    : {},
    RupeeIcon   : '&#8377',
    Color       : '#11284a',
    WebCookie   : 'jahan_user',
    LatCookie   : 'ls_lat',
    LonCookie   : 'ls_lon',
    AdminCookie : 'jahan_admin',
    HelpLine    : '877 9398 321',
    ContactEmail: 'info@jahangirtutorials.com',
    Address     : '304, White Angel, Mumbai-400008.',
    Device      : 'Desktop',
    isLoggedIn	: 'No',
    Connection 	: '',
    OtpNumber   : Math.floor(Math.random() * 10000000000),
    CurrentPage : ''
};

// var Modal = {}, PubSub = {}, Validate = {};

export default Misc;