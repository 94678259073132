import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const [open, setOpen] = React.useState(false);
  const cookies_name =  "read_disc";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.history.back();
    window.close();
  };

  const handleEnter = () => {
    setOpen(false);
    cookies.set(cookies_name, 1, { path: '/' });
  };

  useEffect(() => {
      // if(!cookies.get(cookies_name))
        handleClickOpen();
  }, [])

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Disclaimer</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <p>The Rules of Bar Council of India, do not allow advertisement by advocates through communication in public domain. The visitor acknowledges the following:</p>
            <ul>
                <li>No advertisement, solicitation, invitation or inducement of any sort whatsoever from Jahangir Associates or any of its members to solicit any work through this website;</li>
                <li>The visitor wishes to gain more information about Jahangir Associates for his/her own use and info.;</li>
                <li>The info about Jahangir Associates has been provided to the visitor only on his/her specific request and any info. obtained from this website is completely at the visitor's volition and any transmission, receipt or use of contents on this site would not create any lawyer-client relationship.</li>
                <li>Our website uses cookies to improve your user experience. By using our site, you agree to our use of cookies . To find out more, please see our Cookies Policy & Privacy Policy</li>
            </ul>
            <p>The information provided under this website is solely available at the request of the visitor for informational purposes alone, and the same should not be deemed as soliciting or advertisement. We are not liable for any consequence of any action taken by the user relying on information/material provided in this website. In cases where the visitor has any legal issues, he/she in all cases must seek independent legal advice.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            LEAVE
          </Button>
          <Button variant="contained" onClick={handleEnter} color="primary">
            ENTER
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}