import React from 'react'
import AdvocateSmallSection from './AdvocateSmallSection';
import ContactSection from './ContactSection';

function ContactUs() {
    return (
        <div className="contact__page">
            <ContactSection />
            <AdvocateSmallSection />
        </div>
    )
}

export default ContactUs
