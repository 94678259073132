import React, { useEffect, useRef, useState } from 'react'
import { db } from './firebase';
import parse from 'html-react-parser';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

function PracticeAreaSection() {
    const [practice_areas, setPracticeArea] = useState([]);
    
    useEffect(() => {
        db.collection('practice_areas').onSnapshot(snapshot => {
          setPracticeArea(snapshot.docs.map(doc => ({'id': doc.id, 'data': doc.data()})));
        });
      }, []);

    const [modal_body, setModalBody] = useState('');
    const [modal_title, setModalTitle] = useState('');
    const [modal_companies, setModalCompanies] = useState([]);

    const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const handleOpenModal = (e,b, c) => {
        setModalTitle(b);
        setModalBody(e);
        setModalCompanies(c);
        setScroll('paper');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

    return (
      <div>
        <section className="page__section _practice_area">
        <div className="wrapper">
          <div className="section__head">
            <h2>Practice Area</h2>
          </div>
          <div className="section__body">
            {
              practice_areas.map(({id, data}) => (
                <div onClick={(e) => handleOpenModal(data.text, data.name, data.companies)} key={id} className="practice__card">
                    <span className="__icon">{parse(data.icon)}</span>
                    <h3>{data.name}</h3>
                    <span className="__arrow">
                    <ArrowForwardIcon/>
                    </span>
                </div>
              ))
            }
            </div>
        </div>
      </section>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{modal_title}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <div className="dialog__paragraphs">{parse(modal_body)}</div>

            <div className="company_list">
              <h3>Companies we're working with</h3>
              <div className="__c_list">
            {
              // modal_companies ? 
              modal_companies.map(({name, img}) => (
              <div><img src={img} alt={name} /></div>
              ))
              // : ''
            }
            </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open_modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open_modal}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">{modal_title}</h2>
            <p id="transition-modal-description">{parse(modal_body)}</p>
            <div className="company_list">
              <h3>Companies we're working with</h3>
              <div className="__c_list">
            {
              // modal_companies ? 
              modal_companies.map(({name, img}) => (
              <div><img src={img} alt={name} /></div>
              ))
              // : ''
            }
            </div>
            </div>
          </div>
        </Fade>
      </Modal> */}
      </div>
    )
}

export default PracticeAreaSection
