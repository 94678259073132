import React from 'react'
import TestimonialsList from './TestimonialsList';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

function TestimonialsSection() {
    return (
        <section className="page__section _testimonials">
        <div className="wrapper">
            <div className="section__head">
            <h2>Testimonials</h2>
            </div>
            <div className="section__body">
               <FormatQuoteIcon className="__quote" style={{fontSize: 50, color: '#ddd'}} />
                <TestimonialsList />
            </div>
        </div>
        </section>
    )
}

export default TestimonialsSection
