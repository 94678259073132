import React from 'react'

function AdvocateBigCard(props) {
    const {name, about, img, title} = props['item-data'];
    return (
        <div className="big__team_card">
            <div className="team__img">
                <img src={img} alt="" />
            </div>
            <div className="team__detail">
                <h3>{title}</h3>
                <div className="__about">{about}</div>
                <div className="__action">
                    <h4>{name}</h4>
                </div>
            </div>
        </div>
    )
}

export default AdvocateBigCard
